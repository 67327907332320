<template>
  <div class="big-box">
    <Nlh />
    <!-- 内容 -->
    <div class="xiugai">
      <div class="xiugai-box">
        <div class="step-arrow">
          <div ref="first">身份验证</div>
          <div ref="second">设置新密码</div>
          <div ref="third">完成</div>
        </div>
        <!-- 手机号 -->
        <div class="Auth" v-show="first">
          <div class="common">
            <p>请输入绑定的手机号</p>
            <el-input
              v-model="phone"
              maxlength="11"
              placeholder="请输入手机号"
            ></el-input>
            <span
              style="font-size: 12px; color: red; margin-left: 10px"
              v-show="ges"
              >手机号格式错误</span
            >
          </div>
          <div class="common">
            <p>短信验证码</p>
            <div class="Yzm">
              <el-input
                v-model="yzm"
                maxlength="11"
                placeholder="请输入验证码"
              ></el-input>
              <el-button :disabled="disabled" :plain="true" @click="sendcode">
                {{ btntxt }}
              </el-button>
              <span
                style="font-size: 12px; color: red; margin-left: 10px"
                v-show="ym"
                >验证码不能为空</span
              >
            </div>
          </div>
          <p class="line"></p>
          <div class="Btn">
            <div class="Btn-box" @click="BtnFirst">下一步</div>
            <p>如你已更换手机号，请致电4008-234-521</p>
          </div>
        </div>
        <!-- 设置新密码 -->
        <div class="setUp" v-show="second">
          <el-form
            :model="ruleForm"
            status-icon
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm setUp"
          >
            <el-form-item prop="pass" class="common">
              <p>请输入新密码</p>
              <el-input
                type="password"
                v-model="ruleForm.pass"
                maxlength="20"
                placeholder="请输入6-20位数字、字母或下划线"
                @keyup.native="Fn($event)"
                @keydown.native="Fn($event)"
                autocomplete="off"
              ></el-input>
              <div class="Risk-statement" ref="rick" v-show="Risk"></div>
            </el-form-item>
            <el-form-item prop="checkPass" class="common">
              <p>确认密码</p>
              <el-input
                type="password"
                placeholder="请再次输入密码"
                maxlength="20"
                v-model="ruleForm.checkPass"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-form>
          <p class="line"></p>
          <div class="Btn">
            <!-- <div class="Btn-box" @click="BtnSecond">下一步</div> -->
            <el-button class="Btn-box" type="primary" @click="BackOff"
              >上一步</el-button
            >
            <el-button
              class="Btn-box"
              type="primary"
              @click="BtnSecond, submitForm('ruleForm')"
              >确认</el-button
            >
          </div>
        </div>
        <!-- 密码修改成功 -->
        <div class="success" v-show="third">
          <img src="../../assets/images/forgot/dui.png" alt="" />
          <p>密码修改成功，请使用新密码登录</p>
          <div class="line"></div>
          <div class="Btn-Loign">
            <router-link to="/login">马上登录</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reg } from "@/assets/common/reg";
import { passwprd } from "@/api/my/my.js";
import { sendSms, validSms, validPhone } from "@/api/member/member.js"; //
import { subTk } from "@/api/member/member.js";
import local from "@/api/common/local.js";
/**
 * 引组件
 */
import Nlh from "@/components/layout/nlh";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (reg.pwd.test(value)) {
        callback();
      } else {
        callback(new Error("请输入至少6位"));
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      first: true,
      second: false,
      third: false,
      Risk: false,
      ges: false,
      ym: false,
      phone: "",
      yzm: "",
      confirmpw: "",
      newpw: "",
      btntxt: "获取验证码",
      disabled: false,
      time: 0,
      ruleForm: {
        pass: "",
        checkPass: "",
        age: "",
      },
      rules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  components: {
    Nlh,
  },
  created() {
    this.tkL();
  },
  methods: {
    async tkL() {
      const D = await subTk();
      if (D.code == 0) {
        local.SessionSet("sub_tk", D.data);
      }
    },
    Fn() {
      if (this.ruleForm.pass.length >= 6 && this.ruleForm.pass.length < 10) {
        // console.log("风险");
        this.Risk = true;
        this.$refs.rick.style.background =
          "url(" + require("../../assets/images/forgot/Rick1.png") + ")";
      } else if (this.ruleForm.pass.length >= 10) {
        // console.log("安全");
        this.Risk = true;
        this.$refs.rick.style.background =
          "url(" + require("../../assets/images/forgot/Rick2.png") + ")";
      } else {
        this.Risk = false;
      }
    },
    BackOff() {
      this.second = false;
      this.first = true;
      this.$refs.second.style.background =
        "url(" + require("../../assets/images/forgot/arrow1.png") + ")";
      this.$refs.second.style.color = "#000";
      this.$refs.first.style.background =
        "url(" + require("../../assets/images/forgot/arrow.png") + ")";
      this.$refs.first.style.color = "#5797ffff";
    },
    //验证手机号码部分
    async sendcode() {
      let reg_tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!reg_tel.test(this.phone)) {
        console.log("手机号不能为空");
        this.$message.error({
          message: "手机号格式错误",
          offset: 100,
        });
      } else {
        let a = {
          phonenumber: this.phone,
        };
        const { code, data } = await validPhone(a);
        if (code == 0) {
          if (data.count == 0) {
            this.$message.error({
              message: "手机号未注册",
              offset: 100,
            });
          } else {
            let a = {
              busType: 1,
              phonenumber: this.phone,
            };
            const { code } = await sendSms(a);
            if (code == 0) {
              this.$message({
                message: "验证码已发送",
                type: "success",
                offset: 150,
              });
              this.time = 60;
              this.disabled = true;
              this.timer();
            }
          }
        }
      }
    },
    timer() {
      if (this.time > 0) {
        this.time--;
        this.btntxt = `已发送(${this.time}s)`;
        const tim = setTimeout(this.timer, 1000);
        /**
         * 清除定时器
         */
        // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
        this.$once("hook:beforeDestroy", () => {
          clearInterval(tim);
        });
      } else {
        this.time = 1;
        this.btntxt = "获取验证码";
        this.disabled = false;
      }
    },
    submitForm(formName) {
      // console.log("-----------", formName);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.BtnSecond();
        } else {
          //   console.log("error submit!!");
        }
      });
    },
    // 第一步电话验证码//////////
    async BtnFirst() {
      // 调接口
      let reg_tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;

      if (!reg_tel.test(this.phone)) {
        this.ges = true;
      } else {
        this.ges = false;
      }
      if (this.yzm == "") {
        this.ym = true;
      } else {
        this.ym = false;
      }
      if (this.ges || this.ym) {
        console.log("下一步");
      } else {
        //调接口
        let a = {
          code: this.yzm,
        };
        const { code } = await validSms(a);
        if (code == 0) {
          this.first = false;
          this.second = true;
          this.$refs.first.style.background =
            "url(" + require("../../assets/images/forgot/arrow_1.png") + ")";
          this.$refs.first.style.color = "#000";
          this.$refs.second.style.background =
            "url(" + require("../../assets/images/forgot/arrow1_1.png") + ")";
          this.$refs.second.style.color = "#5797ffff";
        } else {
          this.$message.error({
            message: "验证码错误",
            offset: 150,
          });
        }
      }
    },
    async BtnSecond() {
      // console.log("123");
      this.tkL();
      let a = {
        newPwd: this.ruleForm.pass,
        againPwd: this.ruleForm.checkPass,
        source: 2,
        phonenumber: this.phone,
      };
      const { code, data } = await passwprd(a);
      if (code == 0) {
        console.log("数据", data);
        this.second = false;
        this.third = true;
        this.$refs.second.style.background =
          "url(" + require("../../assets/images/forgot/arrow1.png") + ")";
        this.$refs.second.style.color = "#000";
        this.$refs.third.style.background =
          "url(" + require("../../assets/images/forgot/arrow1_1.png") + ")";
        this.$refs.third.style.color = "#5797ffff";
      } else {
        console.log("错误");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.big-box {
  height: 100%;
  background-color: #f7f7f7;
  box-sizing: border-box;
  .xiugai {
    display: flex;
    justify-content: center;
    padding-top: 100px;
    box-sizing: border-box;
    background-color: #f7f7f7;
    .xiugai-box {
      width: 1200px;
      background-color: #fff;
      color: #666666;
      box-sizing: border-box;
      padding-top: 40px;
      padding-left: 38px;
      padding-bottom: 80px;
      padding-right: 64px;
      height: 100%;
      .step-arrow {
        display: flex;
        div {
          width: 366px;
          height: 42px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: url("../../assets/images/forgot/arrow.png") no-repeat;
        }
        div:nth-child(1) {
          color: #5797ffff;
        }
        div:nth-child(2) {
          background: url("../../assets/images/forgot/arrow1.png") no-repeat;
        }
        div:nth-child(3) {
          background: url("../../assets/images/forgot/arrow1.png") no-repeat;
        }
      }
      .Auth {
        margin-top: 80px;
        .common {
          margin-top: 40px;
          font-size: 16px;
          font-weight: 400;
          color: #777777;
          line-height: 22px;
          /deep/ .el-input {
            width: 320px;
            height: 52px;
            background: #f7f7f7;
            border-radius: 4px;
            margin-left: 12px;
          }
          /deep/ .el-input__inner {
            background: #f7f7f7ff;
            border: none;
            height: 52px;
            outline: none;
          }
          p {
            display: flex;
            align-items: center;
            margin-bottom: 24px;
          }
          p::before {
            content: "";
            display: inline-block;
            width: 2px;
            height: 16px;
            background: #fd686e;
            margin-right: 10px;
          }
          .Yzm {
            /deep/ .el-button {
              width: 120px;
              height: 48px;
              background: #fd686e;
              border: none;
              outline: none;
              color: #ffffff;
              margin-left: 20px;
            }
            /deep/ .is-disabled {
              background-color: #e3e3e3ff;
              color: #adadadff;
            }
            /deep/ .el-button:hover {
              color: none;
            }
          }
        }
        .line {
          margin-top: 120px;
          height: 1px;
          background-color: #ecececff;
        }
        .Btn {
          margin-top: 78px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .Btn-box {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            width: 164px;
            height: 52px;
            background: #fc858a;
            border-radius: 2px;
          }
          p {
            margin-top: 30px;
          }
        }
      }
      .setUp {
        margin-top: 80px;
        .common {
          margin-top: 40px;
          font-size: 16px;
          font-weight: 400;
          // color: #777777;
          line-height: 22px;
          position: relative;
          /deep/ .el-input {
            width: 320px;
            height: 52px;
            background: #f7f7f7;
            border-radius: 4px;
            margin-left: 12px;
          }
          /deep/ .el-input__inner {
            background: #f7f7f7ff;
            border: none;
            height: 52px;
            outline: none;
          }
          /deep/ .el-form-item__content {
            margin-left: 0px !important;
            font-size: 16px;
            /deep/ .el-form-item__error {
              left: 28px !important;
            }
          }
          .Risk-statement {
            position: absolute;
            top: 80px;
            left: 354px;
            width: 90px;
            height: 20px;
            border-radius: 11px;
            border: 1px solid #ececec;
          }
          p {
            display: flex;
            align-items: center;
            margin-bottom: 24px;
          }
          p::before {
            content: "";
            display: inline-block;
            width: 2px;
            height: 16px;
            background: #fd686e;
            margin-right: 10px;
          }
          .Yzm {
            /deep/ .el-button {
              width: 120px;
              height: 48px;
              background: #fd686e;
              border: none;
              outline: none;
              color: #ffffff;
              margin-left: 20px;
            }
            /deep/ .is-disabled {
              background-color: #e3e3e3ff;
              color: #adadadff;
            }
            /deep/ .el-button:hover {
              color: none;
            }
          }
        }
        .line {
          margin-top: 120px;
          height: 1px;
          background-color: #ecececff;
        }
        .Btn {
          margin-top: 78px;
          display: flex;
          // flex-direction: column;
          align-items: center;
          justify-content: center;
          /deep/ .el-button--primary {
            border-color: #fc858a;
          }
          .Btn-box {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            width: 164px;
            height: 52px;
            background: #fc858a;
            border-radius: 2px;
          }
          p {
            margin-top: 30px;
          }
        }
      }
      .success {
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        img {
          margin-top: 110px;
        }
        p {
          margin-top: 50px;
          font-size: 22px;
          color: #333333;
          line-height: 30px;
        }
        .line {
          margin-top: 154px;
          width: 100%;
          height: 1px;
          background-color: #ecececff;
        }
        .Btn-Loign {
          display: flex;
          width: 164px;
          height: 52px;
          margin-top: 79px;
          background: #fc858a;
          border-radius: 2px;
          justify-content: center;
          align-items: center;
          background-color: #fc858aff;
          a {
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>
